// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-exhibitions-js": () => import("./../src/pages/exhibitions.js" /* webpackChunkName: "component---src-pages-exhibitions-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pt-br-contato-js": () => import("./../src/pages/pt-BR/contato.js" /* webpackChunkName: "component---src-pages-pt-br-contato-js" */),
  "component---src-pages-pt-br-exposicoes-js": () => import("./../src/pages/pt-BR/exposicoes.js" /* webpackChunkName: "component---src-pages-pt-br-exposicoes-js" */),
  "component---src-pages-pt-br-index-js": () => import("./../src/pages/pt-BR/index.js" /* webpackChunkName: "component---src-pages-pt-br-index-js" */),
  "component---src-pages-pt-br-publicacoes-da-domesticidade-cotidiana-js": () => import("./../src/pages/pt-BR/publicacoes/da-domesticidade-cotidiana.js" /* webpackChunkName: "component---src-pages-pt-br-publicacoes-da-domesticidade-cotidiana-js" */),
  "component---src-pages-pt-br-sobre-js": () => import("./../src/pages/pt-BR/sobre.js" /* webpackChunkName: "component---src-pages-pt-br-sobre-js" */),
  "component---src-pages-pt-br-trabalhos-js": () => import("./../src/pages/pt-BR/trabalhos.js" /* webpackChunkName: "component---src-pages-pt-br-trabalhos-js" */),
  "component---src-pages-publications-about-everyday-domesticity-js": () => import("./../src/pages/publications/about-everyday-domesticity.js" /* webpackChunkName: "component---src-pages-publications-about-everyday-domesticity-js" */),
  "component---src-pages-work-js": () => import("./../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-templates-category-js": () => import("./../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */)
}

